.leaflet-popup-tip {
  display: none;
}

//.leaflet-popup {
//  height: 1vh;
//  width: 40vw;
//  left: -25vw !important;
//  bottom: 20vh !important;
//}
//
.popup-container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  a {
    text-decoration: none;
    color: rgb(var(--color-primary));
    font-size: 20px;
  }

  div {
    border-radius: 10px;
  }
}

.leaflet-popup-content {
  width: auto !important;
}


.main {
  display: flex;

  .sidebar {
    width: 18vw;
    height: 100vh;
    background: rgb(var(--bg-primary));
    .header {
      width: 100%;
      height: 80px;
      display: grid;
      place-items: center;
      .logo {
        align-items: center;
        display: flex;
        gap: 10px;
        font-size: 28px;
        font-weight: bold;
      }
    }
    .locations {
      padding: 20px;
      height: calc(100% - 140px);
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        background: rgb(var(--bg-primary));
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border: 2px solid transparent;
        background-clip: padding-box;
        background-color: rgb(var(--text-primary) / 0.2);
      }
      .search-box {
        font-size: 14px;
        background: rgb(var(--text-primary) / 0.05);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        position: relative;
        height: 46px;
        position: sticky;
        top: -20px;
        backdrop-filter: blur(20px);
        input {
          height: 100%;
          appearance: none;
          box-shadow: none;
          outline: none;
          background: transparent;
          border: none;
          color: inherit;
          font-size: 14px;
          font-family: inherit;
          width: 100%;
          position: absolute;
          height: 100%;
          left: 0;
          top: 0;
          padding-left: 18px;
          padding-right: 48px;
          box-sizing: border-box;
        }
        svg {
          position: absolute;
          top: 50%;
          right: 18px;
          transform: translateY(-50%);
        }
      }
      .location {
        font-size: 14px;
        padding: 0px 18px;
        height: 46px;
        background: rgb(var(--text-primary) / 0.02);
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        user-select: none;
        .badge {
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          padding: 2px 6px;
          border-radius: 50px;
          background: rgb(var(--color-primary));
          color: rgb(var(--bg-primary));
          box-shadow: 0 6px 10px rgb(var(--color-primary) / 0.5);
          transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        &:not(:last-child) {
          margin-bottom: 14px;
        }
        &.active {
          background: rgb(var(--color-primary));
          color: rgb(var(--bg-primary));
          .badge {
            box-shadow: none;
            background: rgb(var(--text-primary));
          }
        }
        &.sub {
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
          position: relative;
          .content {
            width: 100%;
            margin-top: 20px;
          }
          .badge {
            position: absolute;
            top: 14px;
            right: 18px;
          }
          &.active {
            background: rgb(var(--text-primary) / 0.05);
            color: rgb(var(--text-primary));
            height: auto;
            padding: 18px 18px;
            border-radius: 20px;
            .badge {
              top: 18px;
            }
          }
        }
      }
    }
    .text {
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 6px;
      display: flex;
      color: rgb(var(--text-primary) / 0.5);
      height: 60px;
    }
  }
}

.leaflet-popup-content-wrapper {
  background: rgb(var(--bg-primary));
}


  .location-card {
    position: absolute;
    width: 420px;
    left: 50%;
    bottom: 110%;
    transform: translateX(-50%);
    aspect-ratio: 16/9;
    border-radius: 0.9svw;
    overflow: hidden;
    box-shadow: 0 0 30px rgb(var(--bg-primary)) !important;
    transform-origin: bottom center;
    animation: fadeIn 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 35%;
      padding-inline: 6%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      border-top: 1px solid rgb(var(--text-primary) / 0.2);
      background: rgb(var(--bg-primary)) !important;
      backdrop-filter: blur(8px);
    }
    &__name {
      font-size: 16px;
    }
    &__spawn {
      align-items: center;
      display: flex;
      gap: 4px;
      border-radius: 50px;
      background: rgb(var(--text-primary) / 0.1) !important;
      backdrop-filter: blur(4px);
      font-size: 14px;
      padding: 2% 6%;
      cursor: pointer;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      svg {
        width: 1.5vmin;
        height: 1.5vmin;
      }
      &:hover {
        background: rgb(var(--color-primary));
        color: rgb(var(--bg-primary));
      }
    }
  }