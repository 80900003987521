@use "cssreset";
@use "variables";
@use "main";

@font-face {
  font-family: "ReadexPro";
  src: url("../../public/fonts/readex-pro/ReadexPro-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "ReadexPro";
  src: url("../../public/fonts/readex-pro/ReadexPro-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: "ReadexPro";
  src: url("../../public/fonts/readex-pro/ReadexPro-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "ReadexPro";
  src: url("../../public/fonts/readex-pro/ReadexPro-Bold.ttf");
  font-weight: bold;
}

body {
  font-family: "ReadexPro";
  color: rgb(var(--text-primary));
}
